<template>
  <section id="admission-form-container">
    <TabHeaderInfo
      title="Ficha de admissão"
      tooltip="Ative ou inative os campos da ficha de admissão
      do dashboard cirúrgico "
    />

    <section class="list">
      <ul>
        <li
          class="list-item d-flex align-items-center"
          v-for="admissionFormTemplate in admissionFormTemplates"
          :key="admissionFormTemplate.id"
        >
          <div class="item-info">
            <span>{{ admissionFormTemplate.key }}</span>
          </div>
          <div class="d-flex align-items-center ml-auto">
            <label for="activateTiss" class="bold-text mt-2">
              {{ admissionFormTemplate.active ? 'Ativo' : 'Inativo' }}
            </label>
            <toggle-button
              id="activateTiss"
              class="autoFillBtn"
              :width="40"
              v-model="admissionFormTemplate.active"
              :height="25"
              :color="{ checked: '#00C773' }"
              :disabled="isUpdating"
              @input="
                updateAdmissionFormTemplate(
                  admissionFormTemplate.id,
                  admissionFormTemplate
                )
              "
            />
          </div>
        </li>
      </ul>
    </section>
  </section>
</template>

<script>
import { getCurrentClinic } from '@/utils/localStorageManager'

export default {
  name: 'AdmissionForm',
  components: {
    TabHeaderInfo: () => import('@/components/General/TabHeaderInfo')
  },
  data: () => ({
    clinic: getCurrentClinic(),
    admissionFormTemplates: [],
    isUpdating: false
  }),
  async created() {
    await this.getAdmissionFormTemplates()
  },
  methods: {
    async getAdmissionFormTemplates() {
      const isLoading = this.$loading.show()
      try {
        const { data } = await this.api.getAdmissionFormTemplates(
          this.clinic.id
        )
        this.admissionFormTemplates = data.map(item => {
          return {
            ...item,
            active: Boolean(item.active)
          }
        })
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    async updateAdmissionFormTemplate(id, data) {
      this.isUpdating = true
      try {
        await this.api.updateAdmissionFormTemplate(id, data)
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.isUpdating = false
      }
    }
  }
}
</script>

<style lang="scss">
#admission-form-container {
  .list {
    width: 100%;
    height: 100%;
    border: 1px solid var(--neutral-200);
    border-radius: 8px;
    padding: 20px;

    ul {
      padding: 0;
      list-style-type: none;
    }
  }

  .list-item {
    list-style: none;
    .item-info {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      color: var(--type-active);
    }
    width: 100%;
    height: 6vh;
    font-size: 14px;
    font-weight: 500;
    border-bottom: 1px solid var(--neutral-200);
  }
}
</style>
